import React from 'react'
import Layout from '../../components/DE/LayoutDE'
import { UnderlineYellow } from '../../components/Typography'
import './styles.css'
import * as S from '../../components/Homepage/OurTeam/styled'
import { graphql } from 'gatsby'
import FormAjax from './../../components/Default/FormSimpleAjaxDE'
import HomepageLanding from './../../components/DE/Homepage/LandingPhoto'
import EKPNumbers from '../../components/DE/Homepage/EKPNumbers'
import OurTeam from '../../components/DE/Homepage/OurTeam'

const DE = ({ data: { page } }) => {
  return (
    <Layout meta={page.frontmatter.meta || false}>
      <HomepageLanding />
      <EKPNumbers />
      <OurTeam />
      <S.Wrapper>
        <div className="container Contact--BlueBackground" id="formAnchor">
          <div>
            <h2 className="h2white">Schreiben Sie uns</h2>
            <UnderlineYellow />
            <FormAjax name="HomepageDE" />
          </div>
        </div>
      </S.Wrapper>
    </Layout>
  )
}

export default DE

export const pageQuery = graphql`
  query HomepageDE {
    page: markdownRemark(frontmatter: { slug: { eq: "de" } }) {
      ...Meta
    }
  }
`
