import styled from 'styled-components'
import theme from '../../../../common/theme'

export const Wrapper = styled.div`
  margin-top: 50px;
  text-align: center;
  padding: 0px 15px;
`

export const BlueBox = styled.div`
  background: ${theme.color.blue};
  height: 300px;
  width: 1200px;
  z-index: 0;
`

export const WrapRow = styled.div`
  margin-bottom: 7rem;
  margin-top: 8rem;
  @media (max-width: 768px) {
    margin-top: 0px;
  }
`

export const Image = styled.img`
  margin-top: 32px;
`

export const Name = styled.p`
  margin-top: 2.8rem;
  font-size: 2.6rem;
  font-weight: 600;
  margin-bottom: 0px;
  color: ${theme.color.blue};
  line-height: 30px;
  @media (max-width: 768px) {
    margin-top: 26px;
  }
`

export const NameSmall = styled.p`
  margin-top: 2.8rem;
  font-size: 2.3rem;
  font-weight: 600;
  margin-bottom: 0px;
  color: ${theme.color.blue};
  line-height: 30px;
  @media (max-width: 768px) {
    margin-top: 26px;
  }
`

export const Position = styled.p`
  font-size: 1.8rem;
  margin-bottom: 0px;
  color: ${theme.color.blue};
  line-height: 30px;
  @media (max-width: 768px) {
    line-height: 10px;
  }
`

export const PositionSmall = styled.p`
  font-size: 1.6rem;
  margin-bottom: 0px;
  color: ${theme.color.blue};
  line-height: 30px;
  @media (max-width: 768px) {
    line-height: 10px;
  }
`

export const H2Blue = styled.h2`
  font-family: 'Raleway';
  font-weight: bold;
  font-size: 3.8rem;
  margin-bottom: 17px;
  color: ${theme.color.blue};
`

export const H3Blue = styled.h3`
  font-family: 'Raleway';
  font-weight: bold;
  font-size: 3.8rem;
  color: ${theme.color.blue};
`

export const UnderlineYellow = styled.div`
  margin-top: 10px;
  width: 277px;
  margin-left: auto;
  margin-right: auto;
  height: 0px;
  border: 3px solid ${theme.color.yellow};
  border-radius: 20px;
  @media (max-width: 1414px) {
    width: 200px;
  }
  @media (max-width: 1200px) and (min-width: 576px) {
    width: 300px;
  }
`
